import { Chart } from "react-google-charts";
import DropdownButton from "~/components/dropdown-button";
import { IconBars } from "~/components/icons";
import Link from "~/components/link";
import type { PanelMode } from "~/components/panel";
import Panel from "~/components/panel";
import {
  dayIsAfter,
  differenceInDays,
  endOfQuarter,
  parseDate
} from "~/utils/dates";
import { formatNumber } from "~/utils/formatting";

interface Props {
  label?: string;
  value: number;
  goal: number;
  scaleToQuarter?: string;
  greenCutoff?: number;
  yellowCutoff?: number;
  warning?: string;
  link?: string;
  onClick?: () => void;
  numberFormat?: string;
  formatTicks?: (value: number) => string;
}

export default function MetricGauge({
  label,
  value,
  goal,
  greenCutoff,
  yellowCutoff,
  warning,
  scaleToQuarter,
  link,
  onClick,
  numberFormat,
  formatTicks = (value) => formatNumber(value, { zeroes: true, format: "0,0" })
}: Props) {
  const options = !goal
    ? {
        majorTicks: 3,
        minorTicks: 2
      }
    : {
        redFrom: 0,
        redTo: yellowCutoff || goal / 3,
        yellowFrom: yellowCutoff || goal / 3,
        yellowTo: greenCutoff || (goal / 3) * 2,
        greenFrom: greenCutoff || (goal / 3) * 2,
        greenTo: goal,
        max: goal,
        greenColor: "#5cb85c",
        yellowColor: "#f0ad4e",
        redColor: "#d9534f",
        majorTicks: formatTicks
          ? [formatTicks(0), "", "", "", formatTicks(goal)]
          : undefined
      };

  if (scaleToQuarter) {
    const m1 = parseDate(scaleToQuarter);
    const m2 = endOfQuarter(m1);
    const diff1 = differenceInDays(m2, m1);
    // 91 for example
    const diff2 = differenceInDays(new Date(), m1);
    const percent = diff2 / diff1;
    const yellowPercent = diff2 > 7 ? (diff2 - 7) / diff1 : 0;
    if (dayIsAfter(new Date(), m2)) {
      options.greenFrom = 0.95 * goal;
      options.yellowFrom = 0.9 * goal;
    } else {
      options.greenFrom = percent * goal;
      options.yellowFrom = yellowPercent * goal;
    }
    options.yellowTo = options.greenFrom;
    options.redTo = options.yellowFrom;
  }

  let mode: PanelMode = warning ? "warning" : "default";
  if (value >= (goal / 3) * 2) {
    mode = "success";
  } else if (value >= goal / 3) {
    mode = "warning";
  } else if (value < goal / 3) {
    mode = "danger";
  }

  const links = [];
  if (link) {
    links.push(
      <li key="report">
        <Link to={link}>View Full Report</Link>
      </li>
    );
  }
  if (onClick) {
    links.push(
      <li key="onclick">
        <Link to={onClick}>Edit</Link>
      </li>
    );
  }

  const data = [
    ["Label", "Value"],
    ["", value]
  ];

  const formatters = numberFormat
    ? [
        {
          type: "NumberFormat" as const,
          column: 1,
          options:
            numberFormat === "Money"
              ? {
                  pattern: "#,###",
                  prefix: "$"
                }
              : numberFormat === "Percent"
                ? {
                    pattern: "#.#",
                    suffix: "%"
                  }
                : {}
        }
      ]
    : undefined;

  return (
    <Panel className="mb-0" mode={mode}>
      <Panel.Header
        titleClassName="text-center"
        title={label}
        floatButton
        button={
          links.length > 0 && (
            <div className="show-when-hover-parent">
              <DropdownButton
                label={<IconBars />}
                caret={false}
                small
                align="right"
              >
                {links}
              </DropdownButton>
            </div>
          )
        }
      />
      {warning ? (
        <Panel.Body className="h-[200px]">
          <h4 className="mt-32 text-warning text-center font-bold">
            {warning}
          </h4>
        </Panel.Body>
      ) : (
        <div className="h-full mx-auto flex justify-center">
          <Chart
            chartType="Gauge"
            data={data}
            options={options}
            formatters={formatters}
          />
        </div>
      )}
    </Panel>
  );
}
